import axios, { AxiosError, AxiosResponse } from 'axios';

import { useMutation } from '@tanstack/react-query';
type IImageDeleteRequest = { fileName: string };

export function useImageDelete() {
  return useMutation<AxiosResponse, AxiosError, IImageDeleteRequest>({
    mutationFn: (params) => axios.delete('/image-service/s3/', { params }),
  });
}
